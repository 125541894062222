<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Change Schedule">
      <template #page-header>
        <h2 class="page-title">Change Schedule</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-9">
            <div class="level">
              <div class="level-left">
                <b-button
                  tag="button"
                  class="is-command"
                  icon-left="plus"
                  @click.native="openModalRequest()"
                >
                  New
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-right="magnify"
                icon-right-clickable
                @icon-right-click="searchIconClick"
                :loading="isChangeSchedulePersonalLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="changeScheduleRequest"
            :per-page="perPage"
            :loading="isChangeSchedulePersonalLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="date"
                label="Actual Date"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="change_date"
                label="Date Changed"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.changeDate) }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_reason"
                label="Reason"
                v-slot="props"
              >
                <span>
                  {{ props.row.requestReason }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalRequest(changeScheduleRequest[props.index])
                  "
                ></b-icon>
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <b-sidebar
          v-if="isRightBarOpen && form != null"
          can-cancel
          type="is-light"
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Change Schedule Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar()"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>

            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Actual Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedColumn.date) }}
              </div>
              <div class="column is-4">Change To</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedColumn.changeDate) }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalized">
                {{ selectedColumn.status }}
              </div>
              <div class="column is-4">Reason</div>
              <div class="column is-8 has-text-weight-bold is-capitalized">
                {{ selectedColumn.requestReason }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedColumn.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="selectedColumn.status === 'waiting'"
              >
                <b-button
                  expanded
                  @click="cancelChangeSchedule(selectedColumn.id)"
                >
                  Cancel
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>

        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are you sure?"
          v-if="isModalOpen"
          @hide="closeModalRequest"
          :is-modal="true"
          key="modalKey"
          class="modal-change-schedule"
          remove-scrolling
        >
          <template #page-content>
            <h3 class="modal-title has-text-weight-black">
              Detail Change Date
              <span class="office-pointer-click is-pulled-right">
                <b-icon
                  icon="close"
                  size="is-small"
                  @click.native="closeModalRequest"
                ></b-icon>
              </span>
            </h3>

            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submit)"
                class="is-label-14px-normal form-timeoff-type"
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="form.actualDate"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Actual Date"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-datepicker
                          placeholder="Date"
                          icon-right="calendar"
                          id="date-picker-calendar"
                          :min-date="minDate"
                          :locale="locale"
                          v-model="form.actualDate"
                          class="attendance-datepicker"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></b-datepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-12">
                    <ValidationProvider
                      name="form.selectedDate"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Date Changes To"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-datepicker
                          placeholder="Date"
                          icon-right="calendar"
                          id="date-picker-calendar"
                          :min-date="minDate"
                          :locale="locale"
                          v-model="form.selectedDate"
                          class="attendance-datepicker"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></b-datepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-12">
                    <ValidationProvider
                      name="request_reason"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Request Reason"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-input
                          type="textarea"
                          v-model="form.requestReason"
                          placeholder="Please write your message"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-12">
                    <b-button
                      type="is-primary"
                      expanded
                      native-type="submit"
                      :loading="isSubmitting"
                      :disabled="isEditing && form.status !== 'waiting'"
                    >
                      {{ isEditing ? 'Update' : 'Request Change Date' }}
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { determineFontColorByStatus, showToast } from '@/services/util'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { TimeOffApprovalHistoryComponent },
  data() {
    const today = new Date()
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,

      defaultSortOrder: 'desc',
      sortField: 'change_schedules.updated_at',
      sortOrder: 'desc',
      search: '',

      isChangeSchedulePersonalLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      selectedColumn: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      form: {
        id: null,
        actualDate: null,
        selectedDate: null,
        requestReason: null,
        status: null,
      },

      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isSubmitting: null,
      locale: 'en-GB',
    }
  },
  computed: {
    ...mapGetters({
      changeScheduleRequest: 'changeSchedule/getChangeScheduleRequestData',
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadChangeSchedulePersonal:
        'changeSchedule/fetchChangeScheduleRequestData',
      saveChangeSchedule: 'changeSchedule/saveChangeSchedule',
      updateChangeSchedule: 'changeSchedule/updateChangeScheduleRequest',
    }),

    ...mapMutations({
      setChangeSchedulePersonal: 'changeSchedule/setChangeScheduleRequestData',
    }),

    openModalRequest(row) {
      if (row) {
        this.isEditing = true
        this.form = {
          id: row.id,
          actualDate: new Date(row.date),
          selectedDate: new Date(row.changeDate),
          requestReason: row.requestReason,
          status: row.status,
        }
      }
      this.isModalOpen = true
    },

    closeModalRequest() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    async submit() {
      const form = new FormData()

      form.append('date', moment(this.form.actualDate).format('YYYY-MM-DD'))
      form.append(
        'change_date',
        moment(this.form.selectedDate).format('YYYY-MM-DD')
      )
      form.append('request_reason', this.form.requestReason)

      const today = moment(new Date())
      const actualDate = moment(this.form.actualDate)
      const newDate = moment(this.form.selectedDate)

      this.isSubmitting = true
      if (!this.isEditing) {
        try {
          await this.saveChangeSchedule(form)
          showToast('Data is successfully added!', 'is-success', 'is-bottom')
        } catch (e) {
          if (actualDate < today) {
            showToast('Date must be after today', 'is-danger', 'is-bottom')
          } else if (actualDate >= newDate) {
            showToast(
              'The new date must be after the original date',
              'is-danger',
              'is-bottom'
            )
          } else {
            showToast(e.response.data.message, 'is-danger', 'is-bottom')
          }
        }
      } else {
        try {
          const data = { id: this.form.id, form }

          await this.updateChangeSchedule(data)
          showToast('Data is successfully updated!', 'is-success', 'is-bottom')
        } catch (e) {
          if (actualDate < today) {
            showToast('Date must be after today', 'is-danger', 'is-bottom')
          } else if (actualDate > newDate) {
            showToast(
              'The new date must be after the original date',
              'is-danger',
              'is-bottom'
            )
          } else {
            showToast(e.response.message, 'is-danger', 'is-bottom')
          }
        }
      }

      this.isSubmitting = false
      this.closeModalRequest()
    },

    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadChangeSchedulePersonalList()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setChangeSchedulePersonal([])
      await this.loadChangeSchedulePersonalList()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadChangeSchedulePersonalList()
    },

    resetForm() {
      this.form = {
        id: null,
        actualDate: null,
        selectedDate: null,
        requestReason: null,
      }
    },
    openSidebar(changeSchedule) {
      this.selectedColumn = changeSchedule
      this.isRightBarOpen = true
    },

    closeSidebar() {
      this.isRightBarOpen = false
    },

    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    async cancelChangeSchedule(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        const form = new FormData()

        form.append('status', 'canceled')
        this.isSubmitting = true
        try {
          form.append('_METHOD', 'PUT')
          const data = { id, form }
          await this.updateChangeSchedule(data)
          showToast('Request canceled', 'is-success', 'is-bottom')
          this.isRightBarOpen = false
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-bottom')
        }
      }
    },

    async loadChangeSchedulePersonalList() {
      this.isChangeSchedulePersonalLoading = true
      try {
        const response = await this.actionLoadChangeSchedulePersonal({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isChangeSchedulePersonalLoading = false
    },
  },
  async mounted() {
    this.setChangeSchedulePersonal([])
    await this.loadChangeSchedulePersonalList()
  },
}
</script>
